import config from "../config/config";
import { cdmTypes } from "./master-data"

export default {
    getVendors() {
        return new Promise((resolve, reject) => {
            fetch(`${getEndpoint()}/vendors/`)
                .then(res => {
                    res.json().then(res => {
                        resolve(res)
                    })
                })
                .catch(err => {
                    reject(err)
                })          
        })
    },
    createVendor(vendorData, generate=false) {
        return postMasterData(cdmTypes.VENDOR, vendorData, generate);
    },
    createCustomer(customerData, generate=false) {
        return postMasterData(cdmTypes.CUSTOMER, customerData, generate);
    },
    createManufacturer(manufacturerData, generate=false) {
        return postMasterData(cdmTypes.MANUFACTURER, manufacturerData, generate);
    },
    createItem(itemData, generate=false) {
        return postMasterData(cdmTypes.ITEM, itemData, generate);
    },
    
}

function getEndpoint() {
    const endpoint = config.api.baseUrl
    if (!endpoint)
        throw Error("Invalid API Endpoint!")
    return endpoint
}

function postMasterData(Cdmtype, cdmData, generate) {

    let apiRoute = "";
  
    if (Cdmtype.type === cdmTypes.MANUFACTURER.type) {
        apiRoute = "manufacturers";
    }
    else if (Cdmtype.type === cdmTypes.CUSTOMER.type) {
        apiRoute = "customers";
    }
    else if (Cdmtype.type === cdmTypes.VENDOR.type) {
        apiRoute = "vendors";
    }
    else if (Cdmtype.type === cdmTypes.ITEM.type) {
        apiRoute = "items";
    }
    else {
        throw Error(`CDM Type '${Cdmtype}' is unsupported!`)
    }
    
    let fetchOptions = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: cdmData }) 
    }
    return new Promise((resolve, reject) => {
        fetch(`${getEndpoint()}/${apiRoute}?generate=${generate ? 'yes' : 'no'}`, fetchOptions)
            .then(res => {
                res.json().then(res => {
                    resolve(res)
                })
            })
            .catch(err => {
                reject(err)
            })          
    })
}
