import purchaseOrderGenApi from "../../api/purchase-order-gen-api";

const state = () => ({
    status: {
        loading: false,
        message: "",
        data: {}
    },
    createdPurchaseOrderCDMs: new Map(),
    vendorCdms: [],
    vendorItemCdms: [],
    customerCdms: [],
})

const getters = {
    getStatus: state => { return state.status },
    getcreatedPurchaseOrderCDMs: state => { return state.createdPurchaseOrderCDMs },
    getVendorCDMs: state => { return state.vendorCdms },
    getVendorItemCDMs: state => { return state.vendorItemCdms },
    getCustomerCDMs: (state) => { return state.customerCdms },
}

const actions = {
    createNewPurchaseOrderCDM({commit}, {purchaseOrderData, generate}) {
        updateStatus(commit, true, "Creating Purchase Order CDM....");
        mapCustomerCodeToUltimateDestination(purchaseOrderData);
        purchaseOrderGenApi.createPurchaseOrder(purchaseOrderData, generate).then(res => {
            addCreatedPurchaseOrderCDM(commit, res.data);
            updateStatus(commit, false, "Finished Creating Purchase Order CDM", res);
        });
    },
    loadVendorCDMs({commit}) {
        updateStatus(commit, true, "Loading Vendor CDMs...");
        purchaseOrderGenApi.getVendors().then(res => {
            updateVendorCDMs(commit, res.data);
            updateStatus(commit, false, "Finished loading Vendor CDMs", res);
        })
    },
    loadVendorItemCDMs({commit}, vendorCode) {
        updateStatus(commit, true, "Loading Vendor Item CDMs...");
        purchaseOrderGenApi.getVendorItems(vendorCode).then(res => {
            updateVendorItemCDMs(commit, res.data);
            updateStatus(commit, false, "Finished loading Vendor Items CDMs", res);
        })
    },
    loadCustomerCDMs({ commit }) {
        updateStatus(commit, true, "Loading Customer CDMs...");
        purchaseOrderGenApi.getCustomers().then((res) => {
          updateCustomerCDMs(commit, res.data);
          updateStatus(commit, false, "Finished loading Customer CDMs", res);
        })
    },
}

const mutations = {
    addCreatedPurchaseOrderCDM(state, purchaseOrderCDM) {
        const key = purchaseOrderCDM.PurchaseOrderId;
        state.createdPurchaseOrderCDMs.set(key, purchaseOrderCDM);
    },
    setStatus(state, status) {
        state.status = status
    },
    setVendorCDMs(state, vendorCdms) {
        state.vendorCdms = vendorCdms
    },
    setVendorItemCDMs(state, vendorItemCdms) {
        state.vendorItemCdms = vendorItemCdms
    },
    setCustomerCDMs(state, customerCdms) {
      state.customerCdms = customerCdms
    },
}

const addCreatedPurchaseOrderCDM = (commit, purchaseOrder) => {
    commit('addCreatedPurchaseOrderCDM', purchaseOrder)
}

const updateStatus = (commit, loading, message, data) => {
    commit('setStatus', {loading, message, data})
}

const updateVendorCDMs = (commit, vendorCdms) => {
    commit('setVendorCDMs', vendorCdms)
}

const updateVendorItemCDMs = (commit, vendorItemCdms) => {
    commit('setVendorItemCDMs', vendorItemCdms)
}

const updateCustomerCDMs = (commit, customerCdms) => {
  commit("setCustomerCDMs", customerCdms)
}

const mapCustomerCodeToUltimateDestination = (purchaseOrderData) => {
  for(const line of purchaseOrderData.lines) {
    line.ultimateDestination[0].CustomerCode = purchaseOrderData.CustomerCode;
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}