import { createRouter, createWebHistory } from 'vue-router'
import config from '../config/config';
import Root from '../views/Root.vue'

export const RouteNames = {
    AsnGen: "ASN Generator",
    POGen: "Non-Tecsys PO Generator",
    MasterDataGen: "Non-Tecsys Master Data Generator",
}

const routes = [
  {
    path: config.app.baseUrl,
    name: 'Root',
    component: Root,
    children: [
      {
        path: '',
        name: RouteNames.AsnGen,
        component: () => import('../views/AsnGenerator.vue')
      },
      {
        path: 'pogen',
        name: RouteNames.POGen,
        component: () => import('../views/PurchaseOrderGenerator.vue')
      },
      {
        path: 'mastergen',
        name: RouteNames.MasterDataGen,
        component: () => import('../views/MasterDataGenerator.vue')
      }
    ]
  },
]

const router = createRouter({
  base: config.app.baseUrl,
  history: createWebHistory(),  
  routes
})

export default router
