<template>
  <div id="nav">
    <router-link :to="{name:'ASN Generator'}">ASN Generator</router-link> |
    <router-link :to="{name:'Non-Tecsys PO Generator'}">Non-Tecsys PO Generator</router-link> |
    <router-link :to="{name:'Non-Tecsys Master Data Generator'}">Non-Tecsys Master Data Generator</router-link> |
    <a href="https://intranet.tecsys.corp/display/rd/Receiving+-+Asn+Generator" target="_blank">How to Use</a>
  </div>
  <div class="alert">
    <span><img src="danger.svg" alt="Danger">This tool is strictly for internal Tecsys use only and must not be shared with or deployed at any customer site</span>
  </div>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.alert {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert span {
  display: inline-flex;
  align-items: center;
  padding: 8px;
  background-color: #f1b04e;
  color: black;
  margin-bottom: 15px;
  border-radius: 5px;
}

.alert img {
  margin-right: 8px;
}
</style>
